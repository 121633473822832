import React, { useEffect, useState } from 'react';
import './AdminPage.css'; // Assurez-vous de créer ce fichier CSS

const AdminPage = ({ setLoggedIn }) => {
  const [adminData, setAdminData] = useState({ last_id: null, count: null, totalTexts: null, totalCrossed: null });

  useEffect(() => {
    const fetchData = () => {
      fetch('https://backend.geeknomad.fr/adminData.php')
        .then(response => response.json())
        .then(data => setAdminData(data))
        .catch(error => console.error('There was an error!', error));
    };

    fetchData(); // Appel initial
    const intervalId = setInterval(fetchData, 1000); // Mise à jour toutes les secondes

    return () => clearInterval(intervalId); // Nettoyage en cas de démontage du composant
  }, []);

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.setItem('isLoggedIn', 'false');
  };

  const handleDeleteLastDay = () => {
    fetch('https://backend.geeknomad.fr/adminData.php', {
      method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        alert('Les todolists des dernières 24 heures ont été supprimées.');
      } else {
        alert('Erreur lors de la suppression : ' + data.message);
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  };
  

  return (
    <div className="adminContainer">
      <h1>Bienvenue sur la page admin !</h1>
      <div className="adminData">
        <div className="dataBlock">
          <p className='titleBlock'>Nombre de requêtes</p>
          <p>{adminData.last_id}</p>
        </div>
        <div className="dataBlock">
          <p className='titleBlock'>Nombre total de todolists</p>
          <p>{adminData.count}</p>
        </div>
        <div className="dataBlock">
          <p className='titleBlock'>Nombre total de lignes</p>
          <p>{adminData.totalTexts}</p>
        </div>
        <div className="dataBlock">
          <p className='titleBlock'>Nombre total de textes barrés</p>
          <p>{adminData.totalCrossed}</p>
        </div>
      </div>
      <button className='buttonDeleteLastDay' onClick={handleDeleteLastDay}>Supprimer les todolists des dernières 24h</button>
      <button className='buttonLogout' onClick={handleLogout}>Déconnexion</button>
    </div>
  );
};

export default AdminPage;
