import React, { useState } from 'react';
import './LoginPage.css';

const LoginPage = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    fetch('https://backend.geeknomad.fr/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        setLoggedIn(true);
      } else {
        alert('Invalid credentials');
      }
    })
    .catch(error => {
      console.error('There was an error!', error);
    });
  };

  return (
    <div className="loginContainer">
      <div className="loginBox">
        <h1 className="loginTitle">Login</h1>
        <input 
          type="text" 
          placeholder="Username" 
          className="loginInput"
          onChange={e => setUsername(e.target.value)} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          className="loginInput"
          onChange={e => setPassword(e.target.value)} 
        />
        <button className="loginButton" onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
};

export default LoginPage;
