import {Routes, Route, Navigate} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './App.css';
import Todo from './components/05-todolist/Todolist-React';
import LoginPage from './components/login/LoginPage';
import AdminPage from './components/login/AdminPage';

function App() {
  
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  return (
    <Routes>
        <Route
          path='/admin'
          element={isLoggedIn ? <Navigate to="/admin/home" /> : <LoginPage setLoggedIn={setLoggedIn} />}
        />
        <Route
          path='/admin/home'
          element={isLoggedIn ? <AdminPage setLoggedIn={setLoggedIn} /> : <Navigate to="/admin" />}
        />
        <Route
          path='/*'
          element={<Todo />}      
        />
    </Routes>
  );
}

export default App;
